<template>
  <merchant-layout :title="$t('nav.orders')">

    <template v-slot:actions>

      <!--
      <button class="btn border-primary border text-primary items-center flex mr-4"  v-if="orderList.length>0" @click="showHelp('8027218')"><i class="material-icons text-base mr-2">trending_up</i>Comment booster mes commandes ?</button>
     -->



      <SearchInput v-if="currentMerchant && currentMerchant.status !== 'building'" v-model="filters.search" @change="doSearch"/>
      <button class="btn bg-white text-gray-800 border flex items-center gap-2 hover:ring-0 px-5" id="tour_filterHeader" @click="openFilter" v-if="orderList.length>0||hasFilter">
        <i class="material-icons text-lg">tune</i>
        <span v-if="!hasFilter">Filtrer</span>
        <span v-if="hasFilter">Modifier les filtres</span>
      </button>
      <router-link to="/orders/settings" class="btn bg-primary text-white flex items-center" id="tour_parametersHeader">Paramètres</router-link>
    </template>



    <div class="page-block overflow-x-auto border-primary overflow-hidden mb-10" v-if="preOrderList && preOrderList.items && preOrderList.items.length>0 && !isLoading && page===1">
      <div class="h-12 w-full bg-primary flex items-center justify-between p-4">
        <strong class="text-white uppercase text-xl">Précommandes</strong>
        <button class="flex items-center justify-center text-white" @click="$router.push({name: 'orders_preorders'})">
          <span class="font-medium">Voir toutes les précommandes</span>
          <i class="material-icons text-2xl ml-2">arrow_forward</i>
        </button>
      </div>
      <div>
        <table class="page-table bg-white">
          <tbody>
          <tr v-for="item in preOrderList.items.slice(0, 3)" :key="item.id" @click="openOrder(item.id)">
            <td class="w-20">{{item.ref}}</td>
            <td>
              <span v-if="!item.preorderFor">{{formatIsoToHuman(item.takenAt)}}</span>
              <span v-else>{{ $t('orders.preorder_for', {hour: formatIsoToHuman(item.preorderFor)})}}</span>
            </td>
            <td>{{item.client.name}}</td>
            <td>{{formatPrice(item.amount)}}</td>
            <td>
              <span v-if="item.type==='delivery'">{{ item.courier ? $t('orders.deliver_by', {name: item.courier.name}) : $t('type.delivery') }}</span>
              <span v-else-if="item.type==='pickup' && item.onsitePayment">{{$t('type.pickup_payer_onsite')}}</span>
              <span v-else-if="item.type==='pickup' && !item.onsitePayment">{{$t('type.pickup')}}</span>
              <span v-else-if="item.type==='on_site_counter'">{{$t('type.on_site_counter')}}</span>
            </td>
            <td>
              <div class="flex justify-end items-center h-full">
                <OrderStatusBadge v-if="!item.preorderFor" :status="item.status"/>
                <OrderPreorderBadge v-else :ring-at="item.preorderRingAt" :order="item" :confirmed="item.preorderConfirmedAt!==null"/>
              </div>
            </td>
            <td class="w-10 text-right">
              <button class="btn btn-sm xl:text-base text-white bg-primary px-6 font-bold rounded-md" @click="openOrder(item.id)">{{$t('cta.details')}}</button>
            </td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>


    <div class="page-block overflow-x-auto relative" v-if="!isLoading">
      <div class="absolute h-full w-full z-50 flex items-center justify-center" v-if="isPageLoading"><loader class="h-8 w-8"/></div>
      <table class="page-table" :class="{'opacity-40':isPageLoading}">
        <thead>
        <tr>
          <th>#Ref</th>
          <th>Date</th>
          <th>Client</th>
          <th>Montant</th>
          <th>Type</th>
          <th></th>
          <th></th>
        </tr>
        </thead>
        <tbody v-if="orderList.length>0">
        <tr v-for="item in orderList" :key="item.id" :class="{'bg-red-50/50':item.status === 'canceled'}" @click="openOrder(item.id)">
          <td class="w-20 md:w-40">{{item.ref}}</td>
          <td>
            <span v-if="item.status !== 'preorder'">{{formatIsoToHuman(item.takenAt)}}</span>
            <span v-else>{{ $t('orders.preorder_for', {hour: formatIsoToHuman(item.preorderFor)})}}</span>
          </td>
          <td>{{item.client.name}}</td>
          <td>{{formatPrice(item.amount)}}</td>
          <td>
            <span v-if="item.type==='delivery'">{{ item.courier ? $t('orders.deliver_by', {name: item.courier.name}) : $t('type.delivery') }}</span>
            <span v-else-if="item.type==='pickup' && item.onsitePayment">{{$t('type.pickup_payer_onsite')}}</span>
            <span v-else-if="item.type==='pickup' && !item.onsitePayment">{{$t('type.pickup')}}</span>
            <span v-if="item.type==='on_site_counter'">{{$t('type.on_site_counter')}}</span>
          </td>
          <td>
            <div class="flex justify-end items-center h-full">
              <OrderStatusBadge v-if="item.status !== 'preorder'" :status="item.status"/>
              <OrderPreorderBadge v-else :ring-at="item.preorderRingAt" :confirmed="item.preorderConfirmedAt!==null" :order="item"/>
            </div>
          </td>
          <td class="w-10 text-right">
            <button class="btn-sm xl:text-base text-white bg-primary rounded-md px-5 py-2 font-bold tour_detailsOrder" @click="openOrder(item.id)">{{$t('cta.details')}}</button>
          </td>
        </tr>
        </tbody>
      </table>

      <table-no-elements v-if="orderList.length===0 && (!preOrderList || preOrderList && preOrderList.items && preOrderList.items.length === 0) && !hasFilter"
                         title="Pas de commande"
                         description="Découvrez nos conseils pour booster vos ventes">
        <template v-slot:button>
          <button class="btn bg-primary text-white mt-5" @click="showHelp('8027218')">Découvrir</button>
        </template>
      </table-no-elements>

      <table-no-elements v-else-if="orderList.length===0 && (!preOrderList || preOrderList && preOrderList.items && preOrderList.items.length === 0) && hasFilter"
                         title="Pas de commande avec cette recherche"
                         description=""/>

    </div>


    <Pagination class="mt-4" v-if="!isLoading" @changePage="loadPage" :is-loading="isPageLoading" :pagination="pagination"/>
  </merchant-layout>
</template>

<script>
import {mapGetters} from "vuex";
import {formatIsoToHuman, formatTimestampToHuman} from "@/services/TimeService";
import {formatPrice} from "@/services/FormatService";
import OrderStatusBadge from "@/components/Order/OrderStatusBadge.vue";
import OrderPreorderBadge from "@/components/Order/OrderPreorderBadge.vue";
import {useModal} from "vue-final-modal";
import Order from "@/views/Orders/Order.vue";
import Pagination from "@/components/Ui/Elements/Pagination.vue";
import {showArticle} from "@/services/SystemService";
import SearchInput from "@/components/Ui/Elements/SearchInput.vue";
import TableNoElements from "@/components/Ui/Elements/TableNoElements.vue";
import OrderFilterModal from "@/components/Order/Modal/OrderFilterModal.vue";
import EventBus from "@/EventBus";
import {siteTour, resetTour} from "@/services/TourService";


export default {
  setup(){
    const orderModal = useModal({component: Order});
    const filterModal = useModal({component: OrderFilterModal})
    return {formatTimestampToHuman, formatPrice, orderModal, filterModal}
  },
  data(){
    return {
      isLoading: false,
      page: 1,
      isPageLoading: false,
      pagination: null,
      filters: {
        search: "",
        types: ['delivery', 'pickup', 'on_site_counter'],
        status: ['cancelled', 'pending', 'completed'],
        dateRange: null
      }
    }
  },
  components: {TableNoElements, Pagination, OrderStatusBadge, OrderPreorderBadge, SearchInput},
  computed: {
    ...mapGetters(['currentMerchant']),
    ...mapGetters({
      orderList: 'order/orderList',
      preOrderList: 'order/preOrderList'
    }),
    hasFilter(){
      return this.filters.search || this.filters.types.length !== 3 || this.filters.status.length !== 3 || this.filters.dateRange;
    },
    stepsWithConditions() {
      return [
        {
          toAdd: true,
          option: {
            title: 'Aide',
            intro: 'Retrouvez ici toutes les commandes passées',
          },
        },
        {
          toAdd: true,
          option: {
            title: 'Aide',
            element: '#tour_searchInput',
            intro: 'Vous pouvez rechercher par numéro de commande ou nom de client',
            position: 'bottom'
          },
        },
        {
          toAdd: this.orderList.length>0||this.hasFilter,
          option: {
            title: 'Aide',
            element: '#tour_filterHeader',
            intro: 'Vous pouvez également filtrer les commandes par type (Sur place, Click & Collect, Livraison) ou par état (En cours, Terminée ou Annulée)',
            position: 'bottom'
          },
        },
        {
          toAdd: true,
          option: {
            title: 'Aide',
            element: '#tour_parametersHeader',
            intro: 'Retrouvez et modifiez les paramètres de commande en cliquant ici',
            position: 'bottom'
          },
        },
        {
          toAdd: !(this.orderList.length===0 && (!this.preOrderList || this.preOrderList && this.preOrderList.items && this.preOrderList.items.length === 0)),
          option: {
            title: 'Aide',
            element: '.tour_detailsOrder',
            intro: `Découvrez les détails d'une commande en cliquant sur "Détails"`,
            position: 'left'
          }
        },
        {
          toAdd: true,
          option: {
            title: 'Aide',
            element: '.intercom-launcher',
            intro: 'A tout moment, en cas de problème, vous pouvez nous contacter en utilisant le chat ici',
            position: 'top'
          },
        },
        {
          toAdd: true,
          option: {
            title: 'Aide',
            element: '#tour_needHelp',
            intro: 'Voilà, c\'est fini ! Vous pouvez relancer ce guide à tout moment en cliquant ici',
            position: 'bottom'
          },
        },
      ];
    }
  },
  methods: {
    formatIsoToHuman,
    async openFilter(){
      await this.filterModal.patchOptions({attrs: {baseFilters: this.filters}});
      await this.filterModal.open();
    },
    showHelp(id){
      showArticle(id);
    },
    async openOrder(id){
      await this.orderModal.patchOptions({attrs: {orderId: id}});
      await this.orderModal.open();
    },
    doSearch(){
      this.page = 1;
      this.$router.push({query: {search: this.search, page: 1}});
      this.load();
    },
    async loadPage(page){
      this.$router.push({query: {page}});
      this.page = page;
      this.load();
    },
    async load(){
      if(!this.currentMerchant){
        return;
      }
      const loaded = [];
      if(this.page === 1 && !this.hasFilter){
        loaded.push(this.$store.dispatch('order/getPreOrderListAction', {merchantId: this.currentMerchant.id, page: 1}));
      }
      loaded.push(this.$store.dispatch('order/getOrderListAction', {merchantId: this.currentMerchant.id, page: this.page, filters: this.filters}));
      this.isLoading = true;
      const data = await Promise.all(loaded);

      if(this.page === 1 && !this.hasFilter)
        this.pagination = data[1].pagination;
      else
        this.pagination = data[0].pagination;

      this.isLoading = false;
    },


  },
  async mounted() {
        while (!this.currentMerchant) {
      await new Promise(resolve => setTimeout(resolve, 100));
    }

    EventBus.on('order:filters-update', (filters) => {
      this.filters = filters;
      this.load();
    })

    this.page = this.$route.query.page || 1;
    this.filters.search = this.$route.query.search || '';
    await this.load();


    EventBus.on('resetTour', () => {
      if(this.$route.name === 'orders_list'){
        resetTour(this.$route.name);
        siteTour(this.$route.name, this.stepsWithConditions);
      }
    })

    siteTour(this.$route.name, this.stepsWithConditions);
  }
}
</script>

import { createRouter, createWebHistory } from 'vue-router'
import LoginPage from "@/views/Auth/LoginPage.vue";
import Analytics from "@/views/Analytics.vue";
import Orders from "@/views/Orders/Orders.vue";
import Bookings from "@/views/Booking/Bookings.vue";
import Clients from "@/views/Clients/Clients.vue";
import Marketing from "@/views/Marketing/Marketing.vue";
import Hours from "@/views/Hours/Hours.vue";
import Integrations from "@/views/Integrations/Integrations.vue";
import Menu from "@/views/Menu/Menu.vue";
import MerchantSettings from "@/views/Merchant/MerchantSettings.vue";
import Preorders from "@/views/Orders/Preorders.vue";
import Onboarding from "@/views/Onboarding.vue";
import IntegrationValidationHubrise from "@/views/Integrations/IntegrationValidationHubrise.vue";
import Pending from "@/views/Onboarding/Pending.vue";
import InvoicePage from "@/views/Accounting/InvoicePage.vue";
import MarketingLottery from "@/views/Marketing/MarketingLottery.vue";

const router = createRouter({
  history: createWebHistory(),
  routes: [
      {path: '/onboarding/pending', name: 'onboarding-pending', component: Pending, meta: {auth: false}},

      { path: '/', name: 'analytics', component: Analytics},
      { path: '/onboarding', name: 'onboarding', component: Onboarding},
      { path: '/merchant', name: 'merchant', component: MerchantSettings},

      { path: '/orders/list', name: 'orders_list', component: Orders},
      { path: '/orders/preorders', name: 'orders_preorders', component: Preorders},
      { path: '/orders/settings', name: 'orders_settings', component: () => import('@/views/Orders/OrderSettings.vue')},

      { path: '/menu', name: 'menu', component: Menu},
      { path: '/menu/options', name: 'menu_options', component: () => import('@/views/Menu/MenuOptions.vue')},

      { path: '/booking', name: 'booking', component: Bookings},
      { path: '/booking/settings', name: 'booking_settings', component: () => import('@/views/Booking/BookingSettings.vue')},

      { path: '/clients', name: 'clients', component: Clients},
      { path: '/marketing', name: 'marketing', component: Marketing},
      { path: '/marketing/lottery', name: 'marketing_lottery', component: MarketingLottery},

      { path: '/accounting/summary', name: 'accounting_summary_list', component: () => import('@/views/Accounting/SummaryList.vue')},
      { path: '/accounting/summary/:year/:month', name: 'accounting_summary_month', component: () => import('@/views/Accounting/SummaryMonth.vue')},
      { path: '/accounting/bank_transfers', name: 'accounting_bank_transfers', component: () => import('@/views/Accounting/BankTransfers.vue')},
      { path: '/accounting/bank_transfers/:transactionId', name: 'accounting_bank_transfer_details', component: () => import('@/views/Accounting/BankTransferDetails.vue')},
      { path: '/accounting/bank_transfers/balance/:transactionId', name: 'accounting_bank_balance_details', component: () => import('@/views/Accounting/BankBalanceDetails.vue')},
      { path: '/accounting/invoices/issued', name: 'accounting_issued_invoices', component: () => import('@/views/Accounting/InvoiceIssued.vue')},
      { path: '/accounting/invoices/received', name: 'accounting_received_invoices', component: () => import('@/views/Accounting/InvoiceReceived.vue')},

      { path: '/hours', name: 'hours', component: Hours},
      { path: '/invoice/:invoiceRef', name: 'invoice', component: InvoicePage},

      { path: '/settings/account', name: 'settings_account', component: () => import('@/views/Settings/AccountSettings.vue')},
      { path: '/settings/admin', name: 'settings_admin', component: () => import('@/views/Settings/AdminSettings.vue')},
      { path: '/settings/password', name: 'settings_password', component: () => import('@/views/Settings/PasswordSettings.vue')},

      { path: '/integrations', name: 'integrations', component: Integrations},
      { path: '/integrations/validation/hubrise', name: 'integrations_validation_hubrise', component: IntegrationValidationHubrise},
      { path: '/login', name: 'login', component:  LoginPage, meta: {auth: false}},
    ]
})


router.beforeEach((to, from, next) => {
    if(to.meta && to.meta.auth === false){
        if(localStorage.getItem('access_token')){
            if(to.query?.token && to.name === 'login'){
                localStorage.removeItem('access_token');
                localStorage.removeItem('refresh_token');
                location.href = to.fullPath;
            }

            return next({path: from.path});
        }
        else{
            return next();
        }
    }
    if(!localStorage.getItem('access_token')){
        return next({name: 'login', query: {redirect: to.path}});
    }
    return next();
});

export default router;

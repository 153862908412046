<template>
  <div class="page-block  grid grid-cols-4 md:h-36">
    <div class="h-full flex flex-col items-center py-5 lg:py-0 lg:justify-center border-r text-center px-2">
      <span class="text-2xl lg:text-[2.5em] font-bold text-primary lg:mt-2">{{ getPreparationDurationWithAllSteps(order.statusSteps) }}</span>
      <div class="flex items-center mt-4 flex-col lg:flex-row text-sm lg:text-base leading-tight">
        <span>Préparation</span>
      </div>
    </div>
    <div class="h-full flex flex-col items-center py-5 lg:py-0 lg:justify-center border-r text-center px-2">
      <span class="text-2xl lg:text-[2.5em] font-bold text-primary lg:mt-2">{{formatPrice(order.transactionSum.amount_wt)}}</span>
      <div class="flex items-center mt-4 flex-col lg:flex-row text-sm lg:text-base leading-tight">
        <span>Chiffre d'Affaires TTC</span>
      </div>
    </div>
    <div class="h-full flex flex-col items-center py-5 lg:py-0 lg:justify-center border-r text-center px-2">
      <span class="text-2xl lg:text-[2.5em] font-bold text-primary lg:mt-2">{{formatPrice(order.transactionSum.amount_net_without_debt)}}</span>
      <div class="flex items-center mt-4 flex-col lg:flex-row text-sm lg:text-base leading-tight">
        <span>Net Perçu</span>
      </div>
    </div>
    <div class="h-full flex flex-col items-center py-5 lg:py-0 lg:justify-center text-center px-2">
      <span class="text-2xl lg:text-[2.5em] font-bold text-green-600 lg:mt-2">{{formatPercentage(order.transactionSum.platform_cost_percentage)}}</span>
      <div class="flex items-center flex-col lg:flex-row mt-4 text-sm lg:text-base leading-tight">
        <span>Coût réel Delicity</span>
      </div>
    </div>
  </div>
</template>

<script>
import {formatDuration, formatPercentage, formatPrice} from "@/services/FormatService";
import {getPreparationDurationWithAllSteps} from "../../../services/TimeService";

export default {
  methods: {getPreparationDurationWithAllSteps, formatPercentage, formatPrice},
  props: {
    order: {
      type: Object,
      required: true
    }
  }
}
</script>

<template>
      <page-block title="Vérifier la validité d'un code">
        <label for="" class="form-label">Code promo ou de récupération</label>
        <input type="text" class="form-input" placeholder="Code" v-model="code">
        <div class="mt-5">
          <button class="btn bg-primary text-white" @click="checkLottery" :disabled="isLoading || !code">Vérifier</button>
        </div>
        <div class="px-5 py-3 bg-green-200 text-green-800 text-black mt-4 rounded-md" v-if="result">
          Ce code est valide pour le lot "{{result.item.name}}" <span v-if="result.item.onsite_recovery===1">(Récupération sur place)</span>
        </div>
        <div class="px-5 py-3 bg-red-200  text-red-900 text-black mt-4 rounded-md" v-if="error">
          Ce code n'est pas valable, il n'existe pas.
        </div>
      </page-block>
</template>


<script>
import {mapGetters} from "vuex";
import PageBlock from "@/components/Ui/Elements/PageBlock.vue";
import {checkLotteryCode} from "@/services/LotteryService";
export default {
  components: {PageBlock},
  data(){
    return {
      isLoading: false,
      code: '',
      result: null,
      error: false
    }
  },
  methods:{
    async checkLottery(){
      this.isLoading = true;
      this.result = await checkLotteryCode(this.currentMerchant.id, this.code)
          .catch(() => {
            this.error = true;
          })
      this.isLoading = false;
    }
  },
  watch: {
    code(){
      this.result = null;
      this.error = false;
    }
  },
  computed: {
    ...mapGetters({
      currentMerchant: 'currentMerchant'
    }),
  },
}
</script>

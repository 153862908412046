<template>
  <ModalSliding close-id="order-modal" background-color="bg-gray-100">
    <div class="px-4 pt-3">
      <div class="flex justify-between">
        <div class="flex items-center gap-4">
          <button class="flex items-center justify-center bg-white border px-4 py-1 gap-2 rounded-md hover:bg-gray-50" @click="close"><i class="text-lg material-icons">arrow_back</i>Retour</button>
          <span class="text-xl font-bold uppercase">Commande {{order ? order.ref : ''}}</span>
        </div>

        <div class="flex items-center gap-2" v-if="order">
          <loader class="mr-2 h-5 w-5" v-if="isInvoiceLoading"/>
          <span class="font-bold flex items-center gap-2 text-gray-800 mr-2" v-if="!order.hasInvoices && !order.status === 'canceled'">
            <i class="material-icons text-lg">description</i>Facture pas encore générée
          </span>
          <span class="font-bold flex items-center gap-2 text-gray-800 mr-2" v-else-if="!order.hasInvoices && order.status === 'canceled'">
            <i class="material-icons text-lg">info</i>Delicity ne génère pas de factures sur les commandes annulées
          </span>
          <span class="font-bold flex items-center gap-2 text-gray-800 mr-2" v-else-if="!order.hasInvoices && order.onsitePayment">
            <i class="material-icons text-lg">info</i>Delicity ne génère pas de factures sur les paiement sur place
          </span>
         <!--
          <button class="btn bg-gray-200 flex items-center gap-2 ring-gray-100" :disabled="!order.hasInvoices || isInvoiceLoading"><i class="material-icons text-base">email</i>Envoyer la facture</button>
         -->
          <button class="btn bg-primary text-white flex items-center gap-2" @click="downloadInvoice()" :disabled="!order.hasInvoices || isInvoiceLoading"><i class="material-icons text-base">download</i>Télécharger la facture</button>

        </div>
      </div>
    </div>
    <div class="h-[calc(100vh-3.5rem-1px)] w-full flex flex-col items-center justify-center" v-if="isLoading">
      <loader class="w-7 h-7"/>
      <span class="text-xs mt-4">Chargement...</span>
    </div>


    <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 p-4 overflow-y-auto max-h-[calc(100vh-3.5rem-1px)] pb-20" v-if="order && !isLoading">
      <client-block :order="order"/>
      <order-stat-block :order="order" class="lg:col-span-2"/>
      <order-details-block :order="order"/>
      <pickup-block :order="order" v-if="order.type === 'pickup'"/>
      <delivery-block :order="order" v-else-if="order.type === 'delivery'"/>
      <PageBlock class="py-4">
        <template #base>
          <div v-if="order.status !== 'canceled' && !order.onsitePayment">
            <accounting-summary-month-numbers :data="{resultsExplainedOverThePeriod: order.explained}"/>
          </div>
          <div v-else-if="order.status === 'canceled'" class="px-6">
            <span class="text-red-700">Cette commande a été annulée, aucun paiement n'a donc été effectué.</span>
          </div>
          <div v-else-if="order.onsitePayment" class="px-6 flex flex-col gap-4">
            <span class="text-red-700">Cette commande est réglée sur place et n'est pas comptabilisée par Delicity.</span>
            <span class="text-red-700">Si votre client demande une facture, vous devez l'émettre via votre caisse.</span>
          </div>
        </template>
      </PageBlock>
    </div>
  </ModalSliding>
</template>


<script>
import ModalSliding from "@/components/Ui/Modals/ModalSliding.vue";
import {useVfm} from "vue-final-modal";
import OrderDeliveryMap from "@/components/Order/OrderDeliveryMap.vue";
import {formatDistance} from "@/services/PositionService";
import {formatPrice} from "@/services/FormatService";
import {getHoursFromTimestamp, getTotalOrderDurationWithSteps, formatTimestampToHuman} from "@/services/TimeService";
import OrderStatusBadge from "@/components/Order/OrderStatusBadge.vue";
import OrderPreorderBadge from "@/components/Order/OrderPreorderBadge.vue";
import OrderStatusHistory from "@/components/Order/OrderStatusHistory.vue";
import ClientBlock from "@/components/Order/OrderPage/ClientBlock.vue";
import OrderStatBlock from "@/components/Order/OrderPage/OrderStatBlock.vue";
import OrderDetailsBlock from "@/components/Order/OrderPage/OrderDetailsBlock.vue";
import PickupBlock from "@/components/Order/OrderPage/PickupBlock.vue";
import DeliveryBlock from "@/components/Order/OrderPage/DeliveryBlock.vue";
import PriceRecapBlock from "@/components/Order/OrderPage/PriceRecapBlock.vue";
import {downloadOrderInvoices} from "@/services/OrderService";
import {mapGetters} from "vuex";
import {errorToast} from "@/services/SystemService";
import AccountingSummaryMonthNumbers from "@/components/Accounting/AccountingSummaryMonthNumbers.vue";
import PageBlock from "@/components/Ui/Elements/PageBlock.vue";

export default {
  setup(){
    const vfm = useVfm()
    return {vfm, formatDistance, formatPrice, getHoursFromTimestamp, getTotalOrderDurationWithSteps, formatTimestampToHuman}
  },
  props: {
    orderId: {
      type: Number,
      required: true
    }
  },
  components: {
    PageBlock,
    AccountingSummaryMonthNumbers,
    PriceRecapBlock,
    DeliveryBlock,
    PickupBlock,
    OrderDetailsBlock,
    OrderStatBlock,
    ClientBlock, ModalSliding, OrderDeliveryMap, OrderStatusBadge, OrderPreorderBadge, OrderStatusHistory},
  data(){
    return {
      order: null,
      isLoading: true,
      isInvoiceLoading: false,
    }
  },
  methods: {
    close(){
      useVfm().close('order-modal')
    },
    async downloadInvoice(){
      this.isInvoiceLoading = true;
      await downloadOrderInvoices(this.currentMerchant.id, [this.orderId])
          .catch(() => {
            errorToast('Erreur lors du téléchargement de la facture.');
          })
      this.isInvoiceLoading = false;
    }
  },
  computed: {
    ...mapGetters({
      currentMerchant: 'currentMerchant'
    })
  },
  async mounted() {
    this.isLoading = true;
    this.order = await this.$store.dispatch('order/getOrderAction', {orderId:this.orderId}).catch((order) => {});
    this.isLoading = false;
  }
}
</script>

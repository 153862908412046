<template>
  <merchant-layout :title="$t('nav.marketing_lottery')">
    <div class="grid lg:grid-cols-2 xl:grid-cols-3 gap-6 mb-6">
      <CheckLotteryCode/>
    </div>
  </merchant-layout>
</template>


<script>
import {mapGetters} from "vuex";
import MarketingBlock from "@/components/Marketing/MarketingBlock.vue";
import PageBlock from "@/components/Ui/Elements/PageBlock.vue";
import CheckLotteryCode from "@/components/Marketing/Lottery/CheckLotteryCode.vue";
export default {
  components: {CheckLotteryCode, PageBlock, MarketingBlock},
  computed: {
    ...mapGetters({
      currentMerchant: 'currentMerchant'
    }),
  },
  async mounted() {
    while (!this.currentMerchant) {
      await new Promise(resolve => setTimeout(resolve, 100));
    }
  }
}
</script>
